<template>
  <div>
    <section id="filterCheckTable">
      <b-card-actions action-collapse title="집단별 통계 요약(kWh/㎡)">
        <b-row>
          <b-col>
            <b-form-group label="총량" label-for="engyAllQtyCd">
              <v-select
                id="engyAllQtyCd"
                v-model="filterSelected.engyAllQtyCd"
                :options="commCodeData.ENGY_ALL_QTY_CD"
                label="cdNm"
                placeholder="선택"
                @input="filterSummaryChange()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="기후정규화(예정)" label-for="test">
              <v-select id="test" :options="[]" disabled placeholder="선택" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="규모 정규화" label-for="totareaGbCd">
              <v-select
                id="totareaGbCd"
                v-model="filterSelected.totareaGbCd"
                :options="commCodeData.TOTAREA_GB_CD"
                label="cdNm"
                placeholder="선택"
                @input="filterSummaryChange()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert show small variant="primary">
              <div class="alert-body">
                <span
                  >건물 목록 정보를 확인하시려면
                  <code class="downBtn" @click="excelDown()"
                    ><feather-icon icon="DownloadIcon" />엑셀다운로드</code
                  >를 이용해주세요.
                </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table-simple bordered class="rounded-bottom mb-0" hover responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center align-middle" rowspan="2">
                    표본수
                  </b-th>
                  <b-th class="text-center align-middle" rowspan="2">
                    평균
                  </b-th>
                  <b-th class="text-center align-middle" rowspan="2">
                    중앙값(50%)
                  </b-th>
                  <b-th class="text-center align-middle" colspan="6">
                    백분위
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th class="text-center align-middle">
                    1%
                    <span v-b-modal.per-list class="search-icon">
                      <feather-icon icon="SearchIcon" size="21" @click="modalPerList('1')" style="background-color: #28c76f;border-radius: 5px;"/>
                    </span>
                  </b-th>
                  <b-th class="text-center align-middle">
                    4%
                    <span v-b-modal.per-list class="search-icon">
                      <feather-icon icon="SearchIcon" size="21" @click="modalPerList('4')" style="background-color: #28c76f;border-radius: 5px;"/>
                    </span>
                  </b-th>
                  <b-th class="text-center align-middle">
                    25%
                    <span v-b-modal.per-list class="search-icon">
                      <feather-icon icon="SearchIcon" size="21" @click="modalPerList('25')" style="background-color: #28c76f;border-radius: 5px;"/>
                    </span>
                  </b-th>
                  <b-th class="text-center align-middle">
                    75%
                    <span v-b-modal.per-list class="search-icon">
                      <feather-icon icon="SearchIcon" size="21" @click="modalPerList('75')" style="background-color: #28c76f;border-radius: 5px;"/>
                    </span>
                  </b-th>
                  <b-th class="text-center align-middle">
                    96%
                    <span v-b-modal.per-list class="search-icon">
                      <feather-icon icon="SearchIcon" size="21" @click="modalPerList('96')" style="background-color: #28c76f;border-radius: 5px;"/>
                    </span>
                  </b-th>
                  <b-th class="text-center align-middle">
                    99%
                    <span v-b-modal.per-list class="search-icon">
                      <feather-icon icon="SearchIcon" size="21" @click="modalPerList('99')" style="background-color: #28c76f;border-radius: 5px;"/>
                    </span>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="filterSummaryData.length > 0">
                <b-tr v-for="(data, index) in filterSummaryData" :key="index">
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['cnt'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['avgQty'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc50'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc01'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc04'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc25'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc75'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc96'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                  <b-td
                    v-if="filterSelected.engyAllQtyCd != null && filterSelected.totareaGbCd != null"
                    class="text-center align-middle"
                  >
                    {{ data['perc99'] | makeComma }}
                  </b-td>
                  <b-td v-else>
                    -
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else>
                <b-tr>
                  <b-td class="text-center align-middle" colspan="9" variant="light"
                    >검색된 데이터가 없습니다.
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-card-actions>
    </section>
    <b-modal id="per-list" centered size="lg" :title="perTitle" hide-footer>
      <filter-static-per-list :per-list-value="perListValue"></filter-static-per-list>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import vSelect from 'vue-select';
import { utils } from '@/utils/commUtils';
import {
  BAlert,
  BCol,
  BFormGroup,
  BModal,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
  VBModal,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import FilterStaticPerList from '~/filter/FilterStaticPerList.vue';


export default {
  components: {
    FilterStaticPerList,
    BModal,
    BCardActions,
    vSelect,

    BAlert,
    BTableSimple,
    BFormGroup,
    BRow,
    BCol,
    BThead,
    BTr,
    BTh,
    BTd,
    VBModal,
    BTbody,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perListValue: '',
    };
  },
  computed: {
    ...mapGetters({
      filterSummaryData: 'filter/getDataSummary',
      commCodeData: 'filter/getCommCodeData',
      filterSelected: 'filter/getFilterFetchedInfo',
      result: 'filter/getResult',
    }),
    perTitle() {
      return `백분위 ${this.perListValue}%`;
    },
  },

  methods: {
    // 필터 조건 변경 이벤트
    filterSummaryChange() {
      const { engyAllQtyCd } = this.filterSelected;
      const { totareaGbCd } = this.filterSelected;

      // 총량과 규모 정규화가 모두 선택되었을 경우에만 조회함
      if (engyAllQtyCd !== null && totareaGbCd !== null) {
        // 집단별 통계 요약 테이블 조회
        const filter = this.filterSelected;
        this.$store.dispatch('filter/FETCH_FILTER_SUMMARY_DATA', filter);
      }
    },
    // 건물 목록 엑셀 다운로드
    excelDown() {
      utils.fnAlert(this, "다운로드 권한이 없습니다");
      // alert('20240809.임시로 새창:건물위치바로가기 기능으로 샘플코드 작성함.');
      // const newWindow = window.open(
      //   '/building-service',
      //   'new-building-service',
      //   'toolbar=no,menubar=no,location=no,status=no,resizable=yes,scrollbars=yes',
      // );
      // newWindow.addEventListener('load', () => {
      //   // 새 창이 로드완료될 때까지 대기
      //   if (newWindow.gis.search.moveToMultiBldCrd) {
      //     //건물위치 찾아가기 map함수가 로드되었는지 확인
      //     const bulds = [];
      //     const buld1 = {};
      //     buld1.bldPk = '11140-4769'; //샘플건물1
      //     buld1.x = 954299;
      //     buld1.y = 1951414;
      //     bulds.push(buld1);
      //     const buld2 = {};
      //     buld2.bldPk = '11140-100205773'; //샘플건물2
      //     buld2.x = 954332;
      //     buld2.y = 1951331;
      //     bulds.push(buld2);
      //     const buld3 = {};
      //     buld3.bldPk = '11140-4620'; //샘플건물3
      //     buld3.x = 954228;
      //     buld3.y = 1951246;
      //     bulds.push(buld3);
      //     newWindow.gis.search.moveToMultiBldCrd(map, bulds); //새창에서 해당 건물위치로 찾아가기 함수 호출
      //   }
      // });

      utils.fnConfirm(this, '다운로드 하시겠습니까?', '표본수 <strong>1만건 이상</strong>인 데이터 다운로드 시 <br/>오랜 시간이 소요됩니다.')
        .then(async result => {
          if (result.value) {
            const filterSelected = this.filterSelected;
            await this.$store.dispatch("filter/DOWNLOAD_EXCEL", filterSelected);
            if (!this.result.resultFlag) {
              utils.fnAlert(this, this.result.resultMsg);
            }
          }
        });
    },
    modalPerList(val) {
      this.perListValue = val;
    },
  },
};
</script>

<style scoped>
.downBtn {
  cursor: pointer;
}

.downBtn:hover {
  background-color: #28c76f;
  color: #ffffff;
}
.search-icon {
  cursor: pointer;
  border-radius: 5px;
 
}
</style>
