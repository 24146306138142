<template>
  <div>
    <div v-show="!loading">
      <b-row>
        <b-col md="6" sm="8" />
        <b-col md="6" class="mb-1">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="검색" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                초기화
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            :current-page="currentPage"
            :fields="header"
            :items="rows"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            table-class="edocmy-table"
            thead-class="edocmy-thead"
            tbody-class="edocmy-tbody"
            @filtered="onFiltered"
          >
            <!-- bldNm이 존재하지 않을 때 '-'로 표기 -->
            <template v-slot:cell(bldNm)="row">
              {{ row.item.bldNm || '-' }}
            </template>

            <!-- dongNm이 존재하지 않을 때 '-'로 표기 -->
            <template v-slot:cell(dongNm)="row">
              {{ row.item.dongNm || '-' }}
            </template>
            <template v-slot:cell(mgmBldPk)="row">
              <div class="d-flex justify-content-between">
                <div class="d-inline-block">
                  <b-badge size="sm" pill :variant="row.item.mgmBldPk ? 'warning' : 'secondary'" class="bldBadge">
                    에너지정보
                  </b-badge>
                </div>
                <div class="d-inline-block ml-auto">
                  <feather-icon size="26" icon="ZoomInIcon" class="cursor-pointer" @click="goDtl(row.item)" />
                </div>
              </div>
            </template>
          </b-table>
          <div class="tbFooter">
            <div>
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows.length" aria-controls="my-table" />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-show="loading" class="text-center pt-1">
      <b-spinner class="m-5" label="Busy" variant="dark" />
    </div>
  </div>
</template>

<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow, BPagination, BTable, BModal, BInputGroupAppend, BInputGroup, BBadge, BSpinner } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { mapGetters } from 'vuex';
import BuildTotalMain from '~/building/buildTotalInfo/buildTotalMain.vue';

let filterPerGisMap = null; // 팝업.윈도우.지도.(현재 화면에서만 전역으로 사용)

export default {
  components: {
    BSpinner,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BTable,
    BuildTotalMain,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    VueGoodTable,
    BPagination,
  },
  props: {
    perListValue: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      searchWord: null,
      loading: true,
      selected: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      header: [
        { label: '건축물명', key: 'bldNm', class: 'bldNm-class', sortable: true },
        { label: '건물명칭', key: 'dongNm', class: 'dongNm-class' },
        { label: '소재지', key: 'platAddr', class: 'platAddr-class' },
        { label: '건물정보 ', key: 'mgmBldPk', class: 'createdDt-class' },
      ],
      rows: [],
      // 선택한 건물 PK
      selectMgmBlkPk: '',
      modalBuildTotal: false, // 건물 정보 토탈
      buildTotalInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      filterSelected: 'filter/getFilterFetchedInfo',
    }),
    filterWithPerList() {
      return {
        ...this.filterSelected,
        perListValue: this.perListValue,
      };
    },
  },
  async mounted() {
    this.rows = await this.$store.dispatch('filter/FETCH_PER_LIST', this.filterWithPerList);
    this.loading = false;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async goDtl(bldInfoData) {
      const bulds = [];
      const buld1 = {
        bldPk: bldInfoData.mgmBldPk, // 건물 정보 설정
        x: bldInfoData.stX,
        y: bldInfoData.stY,
      };
      bulds.push(buld1);

      if (filterPerGisMap && !filterPerGisMap.closed) {
        // 이미 창이 열려 있으면 바로 moveToMultiBldCrd 호출
        if (filterPerGisMap.gis.search.moveToMultiBldCrd) {
          filterPerGisMap.gis.search.moveToMultiBldCrd(map, bulds); // 지도 이동 함수 바로 실행
        }
      } else {
        //창이 닫혀있거나 없으면 새창 띄우고 moveToMultiBldCrd 호출
        filterPerGisMap = window.open('/building-service', 'new-building-service', 'toolbar=no,menubar=no,location=no,status=no,resizable=yes,scrollbars=yes');
        filterPerGisMap.addEventListener('load', () => {
          if (filterPerGisMap.gis.search.moveToMultiBldCrd) {
            filterPerGisMap.gis.search.moveToMultiBldCrd(map, bulds); // 새 창 로드 후 지도 이동 함수 실행
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
